import React from 'react';
import BlogPage from '../blogpage';

export default class extends React.Component {

    formatDate = (date) => {

        const d = new Date(date);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
        return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
    }

    shareOpen = (url, width, height) => {

        window.open(url, "", "toolbar=0, status=0, width=" + width + ", height=" + height + "");
    }

    render() {

        let { blog, blogs } = this.props.pageContext;

        const featuredBlog = blog;

        const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=https://doitforme.services/blog/${blog.url}&t=${encodeURI(blog.title)}`;
        const twitterUrl = `https://twitter.com/intent/tweet?url=https://doitforme.services/blog/${encodeURI(blog.url)}&text=${encodeURI(blog.title)}`;
        const pinterestUrl = `https://pinterest.com/pin/create/button/?url=https://doitforme.services/blog/${encodeURI(blog.url)}&title=${encodeURI(blog.title)}`;

        return (
            <BlogPage {...this.props}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <div className="article-summary">
                                <div className="title">
                                    {featuredBlog.title}
                                </div>
                                <div className="meta d-flex">
                                    <div className="when">{this.formatDate(featuredBlog.date)}</div>
                                    <div className="author">{featuredBlog.author}</div>
                                </div>
                                <div className="summary" dangerouslySetInnerHTML={{ __html: featuredBlog.text }}>
                                </div>
                                <div className="share d-flex align-items-center justify-content-end">
                                    <div className="share-title">Share</div>
                                    <div
                                        className="share-ico"
                                        onClick={() => this.shareOpen(fbUrl, 650, 420)}><i className="la la-facebook"></i></div>
                                    <div className="share-ico" onClick={() => this.shareOpen(twitterUrl, 650, 420)}><i className="la la-twitter"></i></div>
                                    <div className="share-ico" onClick={() => this.shareOpen(pinterestUrl, 650, 420)}><i className="la la-pinterest"></i></div>
                                </div>
                            </div>                        
                        </div>
                        <div className="col-12 col-lg-5">
                            <h3>Related Posts</h3>
                            <div className="article-list">
                                {
                                    blogs.map((blog) => (
                                        <div key={blog.id} className="article d-flex align-items-center justify-content-between">
                                            <div className="img d-flex align-items-center">
                                                <img alt={blog.title} src={blog.img} />
                                            </div>
                                            <div className="title d-flex flex-column align-items-start justify-content-center">
                                                {blog.title}
                                                <div className="when">
                                                    {this.formatDate(blog.date)}
                                                </div>
                                            </div>
                                        </div>
    
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BlogPage>
        )
    }
}
